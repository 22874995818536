import Vue from 'vue'
import Vuex from 'vuex'
import Api from "@/store/Api";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		equalHeights: {
			demo: {
				item1: 100,
				item2: 200
			}
		}
	},
	mutations: {
		setEqualHeightItem: (state, {id, itemId, height}) => {
			const equalHeight = Object.assign({}, state.equalHeights)
			if (!equalHeight[id]) {
				equalHeight[id] = {}
			}
			equalHeight[id][itemId] = height;
			
			state.equalHeights = equalHeight
		},
	},
	actions: {},
	modules: {
		Api
	}
	
})
