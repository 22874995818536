<template>
  <i class="fas fa-spinner"></i>
</template>

<script>
export default {
  name: "LoadingIcon"
}
</script>

<style scoped>


</style>
