<template>
  <div id="contact_details" class="">
    <h3>{{ $t('contact_details.header') }}</h3>
    <div class="row">
      <div class="col-sm-5">
        <span v-for="key in ['company', 'street', 'zip', 'city', 'country']" :class="key">
          {{ user?.[key] }}
        </span>
      </div>
      <div class="col-sm-7">
        <div v-for="key in ['zusatz', 'vatin', 'phone', 'website']"
             v-html="$t('contact_details.fields.' + key, user)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

import AppCore from "@/mixins/app-core";

export default {
  name: 'ContactDetails',
  mixins: [AppCore],
  computed: {
    ...mapState({
      user: state => state.Api.SecureUsers.current
    })
  },
  methods: {
    getLabelForRow(key) {
      switch (key) {
        case 'credits':
          return this.$t('account_details.row.credits')
        case 'apikey':
          return this.$t('account_details.row.apikey')
        case 'apicid':
          return this.$t('account_details.row.apicid')
      }
    }
  }
}
</script>
