import Vue from 'vue';
import App from './App.vue';

import Check   from './views/Check';
import Credits from './views/Credits';

import store from './store';

// import ConfigLoader from "pixelstein-vue-app-package/src/plugins/config-loader";
import {VueRuntimeConfigPlugin} from '@pascholda1/vue-runtime-config-plugin';
import i18n                      from './i18n';

Vue.config.productionTip = false;

import ApiErrorHandlerPlugin
                      from '@pixelstein/api-error-handler-plugin/dist/ApiErrorHandlerPlugin';
import AccountDetails from '@/views/AccountDetails';
import ContactDetails from '@/views/ContactDetails';
import UserDetails    from '@/views/UserDetails';
import Orders         from '@/views/Orders';
import MassCheck      from '@/views/MassCheck';

const apiErrorHandlerPlugin = new ApiErrorHandlerPlugin();
Vue.use(apiErrorHandlerPlugin);

apiErrorHandlerPlugin.addMiddleware(error => alert(error.message), true)

;(async () => {

  const runtimeConfigPlugin = new VueRuntimeConfigPlugin({
    configPath: '/app/config',
    staticConfig: process.env,
  });

  await runtimeConfigPlugin.loadEnvironment({
    environment: process.env.NODE_ENV,
  });

  Vue.use(runtimeConfigPlugin);

  // Init App
  if (!document.getElementById('app')) {
    const appNode = document.createElement('div');
    appNode.id = 'app';
    document.body.appendChild(appNode);
  }

  window.app = new Vue({
    // router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');

  // Init Views if present
  const views = {
    check: Check,
    mass_check: MassCheck,
    credits: Credits,
    account_details: AccountDetails,
    contact_details: ContactDetails,
    user_details: UserDetails,
    orders: Orders,
  };
  const viewFactory = ({elementId, Component}) => {
    if (document.getElementById(elementId)) {
      window[elementId] = new Vue({
        // router,
        store,
        i18n,
        render: h => h(Component),
      }).$mount('#' + elementId);
    }
  };
  Object.entries(views)
      .forEach(([elementId, Component]) => viewFactory({elementId, Component}));

})();
