<template>
  <div id="mass_check">

    <div class="row justify-content-center mb-5">
      <div class="col-sm-6 col-md-4 col-lg-3 text-center">
        <form>
          <form-multi-file-upload
              ref="upload_form"
              :button-label-html-first-item="$t('mass_check.upload_button_label', {allowedFileExtensions: allowedFileExtensions.join(', ')})"
              :allowed-file-extensions="allowedFileExtensions"
              :allowed-mime-types="allowedMimeTypes"
              :max-file-count="1"
              v-model="selectedFiles"
          />
          {{ uploadProgress }}
        </form>
      </div>
    </div>

    <div v-if="files.length > 0" class="bg-white p-4">
      <h2>{{ $t('mass_check.file_list.header') }}</h2>

      <div class="form-row font-weight-bold border-light border-bottom">
        <div v-for="column in fileListColumns"
             :key="column"
             :class="column.className"
        >
          {{ getLocalizedFileListColumn(column.key) }}
        </div>
      </div>

      <ps-accordion-container>
        <ps-accordion-collapse
            v-for="file in files"
            :key="file.fileid"
            :initial-active="file.fileid === previewFileId"
        >
          <template #header="{active}">
            <div class="form-row align-items-center py-3 border-bottom border-light"
                 :class="{'bg-light':active, 'bg-white': !active}"
                 style="transition: all .3s"
                 @click="showFilePreview(file)"
            >
              <div v-for="column in fileListColumns"
                   :key="column"
                   :class="column.className"
              >
                <template v-if="column.key === 'actions'">
                  <i class="fas fa-chevron-left collapse-icon" :class="{active}"></i>
                </template>
                <template v-if="column.key === 'status'">
                  <loading-icon class="d-inline-block m-2" v-if="file.status === 'CHECKING'"/>
                  <template v-else-if="file.status === 'NEW'">
                    <i class="fas fa-certificate fa-2x"></i>
                    <span class="new-text">
                      {{ $t('mass_check.file_list.new') }}
                    </span>
                  </template>
                  <template v-else>
                    <i class="fas fa-check d-inline-block m-2"></i>
                  </template>
                </template>
                <template v-else-if="column.key === 'progress'">
                  <div v-if="file.status === 'CHECKING'"
                       class="progress">
                    <div class="progress-bar bg-secondary"
                         role="progressbar"
                         :style="'width: '+file.progress+'%;'"
                         :aria-valuenow="parseInt(file.progress)"
                         aria-valuemin="0"
                         aria-valuemax="100"
                    >
                      {{ parseInt(file.progress) }}%
                    </div>
                  </div>
                  <template v-else-if="file.status === 'FINISHED'">
                    {{ $t('mass_check.progress.finished') }}
                  </template>
                  <template v-else-if="file.status === 'NEW'">
                    {{ $t('mass_check.progress.new') }}
                  </template>
                </template>
                <template v-else>
                  {{ file?.[column.key] }}
                </template>
              </div>
            </div>
          </template>
          <template #content="{active}">
            <div class="form-row border-left border-bottom border-right border-light">

              <div class="col-12 px-3">
                <div class="form-row mb-3">
                  <div class="col-12">
                    <h2>{{ $t('mass_check.file_preview.header', file) }}</h2>
                  </div>

                  <div class="col-md-8">
                    <p>
                      <span v-html="$t('mass_check.file_detail.records', file)"/><br>
                      <span v-html="$t('mass_check.file_detail.price', file) "/><br>
                      <span v-html="$t('mass_check.file_detail.products', file) "/><br>
                      <span v-html="$t('mass_check.file_detail.created', {created: toFormattedDateTime(file.created, $t('general.dateTimeFormat'))}) "/><br>
                    </p>
                    <p>
                      <span v-html="$t('mass_check.file_detail.delete_time', {deleteTime: toFormattedDate(file.deleteTime, $t('general.dateTimeFormat'))}) "/>
                      &nbsp; <a v-if="file.status !== 'CHECKING'"
                                href="#"
                                @click.prevent="tryDeleteFile(file)"
                    >{{ $t('mass_check.file_detail.delete') }}</a>
                    </p>
                  </div>

                  <div class="col-md-4">
                    <template v-if="file.status === 'NEW'">
                      <div class="bg-light p-3 font-weight-bold">
                        {{ $t('mass_check.file_detail.check_info') }}
                      </div>
                      <button type="button"
                              class="btn btn-secondary btn-block"
                              @click.prevent="checkFile(file)"
                              v-html="$t('mass_check.file_detail.check_button', file)"
                      />
                    </template>
                    <template v-if="file.status === 'CHECKING'">
                      <div class="bg-light p-3 ">
                        {{
                          $t('mass_check.file_detail.check_wait_for_result', {eta: getEstimatedTime(file)})
                        }}
                      </div>
                    </template>
                    <template v-if="file.status === 'FINISHED'">
                      <button
                          type="button"
                          class="btn btn-block btn-secondary mb-3"
                          @click="downloadCheckResult(file)"
                          v-html="$t('mass_check.file_detail.check_result_button', file)"
                      />
                      <button
                          type="button"
                          class="btn btn-block btn-outline-secondary"
                          @click="downloadCheckReport(file)"
                          v-html="$t('mass_check.file_detail.check_report_button', file)"
                      />
                    </template>
                  </div>
                </div>

                <div class=" table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th v-for="(column, idx) in file?.columns || []" :key="['th',idx,column].join('_')">
                        {{ getLocalizedFileListColumn(column) }}
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, rowIdx) in file?.preview || []"
                        :key="['tr',rowIdx].join('_')"
                    >
                      <td v-for="(column, idx) in row"
                          :key="['tr', rowIdx, idx, column].join('_')"
                      >
                        {{ column }}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </template>
        </ps-accordion-collapse>
      </ps-accordion-container>
    </div>


    <!-- Confirm Upload -->
    <ps-modal-general-modal
        v-if="selectedFiles.length > 0"
        dialog-classes="modal-lg"
        @close="closeUploadModal"
    >
      <template #header>
        <h2>{{ $t('mass_check.choose_checks') }}</h2>
      </template>
      <form class="upload-form"
            @submit.prevent="startFileUpload"
      >
        <form-switch
            :label="$t('product.sc')"
            :disabled="uploadProgress"
            v-model="products.SC.enabled"
        />
        <p class="small">{{ $t('product_description.sc') }}</p>
        <form-switch
            :label="$t('product.fk')"
            :disabled="!!uploadProgress"
            v-model="products.FK.enabled"
        />
        <p class="small">{{ $t('product_description.fk') }}</p>
        <form-switch
            :label="$t('product.nc')"
            :disabled="!!uploadProgress"
            v-model="products.NC.enabled"
        />
        <p class="small">{{ $t('product_description.nc') }}</p>
        <form-switch
            :label="$t('product.emx')"
            :disabled="!!uploadProgress"
            v-model="products.EMX.enabled"
        />
        <p class="small">{{ $t('product_description.em') }}</p>
        <form-switch
            :label="$t('product.pbt')"
            :disabled="!!uploadProgress"
            v-model="products.PBT.enabled"
        />
        <p class="small">{{ $t('product_description.pbt') }}</p>
        <form-switch
            :label="$t('product.vatid')"
            :disabled="!!uploadProgress"
            v-model="products.VATID.enabled"
        />
        <p class="small">{{ $t('product_description.vatid') }}</p>
      </form>
      <template #footer>
        <button type="button"
                :disabled="!!uploadProgress"
                class="btn btn-secondary"
                @click="startFileUpload"
        >
          {{ $t('mass_check.upload.upload_label') }}
          <progress
              v-if="uploadProgress"
              max="1"
              :value="uploadProgress.progress"
          />
        </button>
      </template>
    </ps-modal-general-modal>

    <privacy-view-modal
        :accepted="!!user?.dsgvo_contract"
    />

    <debug-only>
      <pre>{{ $data }}</pre>
    </debug-only>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

import AppCore from "@/mixins/app-core";
import DebugOnly from "@/components/DebugOnly";
import FormSwitch from "@pixelstein/ps-form/components/PsFormSwitch";
import FormMultiFileUpload from "@pixelstein/ps-form/components/PsFormMultiFileUpload";
import PsModalGeneralModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal'
import PsAccordionContainer from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionContainer'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'
import DateFormats from "pixelstein-vue-app-package/src/mixins/date-formats";
import LoadingIcon from "@/components/LoadingIcon";
import ApiError from "@pixelstein/api-store-utils/dist/errors/ApiError";
import PrivacyViewModal from "@/components/PrivacyViewModal";

const PROGRESS_TIMEOUT = 1000; // milliseconds

export default {
  name: 'Credits',
  mixins: [AppCore, DateFormats],
  components: {
    PrivacyViewModal,
    LoadingIcon,
    DebugOnly,
    FormSwitch,
    FormMultiFileUpload,
    PsModalGeneralModal,
    PsAccordionCollapse,
    PsAccordionContainer
  },
  data() {
    return {
      uploadProgress: null,
      allowedFileExtensions: ['csv', 'xlsx', 'xls'],
      allowedMimeTypes: ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
      fileListColumns: [
        {
          key: 'status',
          className: 'col-6 col-sm-1'
        },
        {
          key: 'filename',
          className: 'col-12 col-sm'
        },
        {
          key: 'progress',
          className: 'col-6 col-sm-3'
        },
        {
          key: 'actions',
          className: 'col-12 col-sm-1 text-right pr-3'
        }
      ],
      products: {
        SC: {
          enabled: false,
          enabledFields:
              ['country', 'zip', 'city', 'street', 'hno'],
          requiredFields: ['country', 'zip', 'city', 'street', 'hno'],
        },
        SCX: {enabled: false, enabledFields: [], requiredFields: []},
        SC_INT: {enabled: false, enabledFields: [], requiredFields: []},
        FK: {
          enabled: false,
          enabledFields: ['firstname', 'lastname', 'city', 'street', 'phone'],
          requiredFields: ['firstname', 'lastname'],
        },
        NC: {
          enabled: false,
          enabledFields: ['firstname', 'lastname', 'salutation', 'title'],
          requiredFields: ['lastname'],
        },
        EM: {enabled: false, enabledFields: ['email'], requiredFields: ['email']},
        EMX: {enabled: false, enabledFields: ['email'], requiredFields: ['email']},
        PB: {enabled: false, enabledFields: [], requiredFields: []},
        PBT: {
          enabled: false,
          enabledFields: ['firstname', 'lastname', 'country', 'zip', 'city', 'street', 'hno', 'phone'],
          requiredFields: ['lastname', 'country', 'zip', 'city', 'street', 'hno'],
        },
        VATID: {enabled: false, enabledFields: ['vatin'], requiredFields: ['vatin']},
        VATIDX: {enabled: false, enabledFields: ['vatin'], requiredFields: ['vatin']}
      },
      selectedFiles: [],
      previewFileId: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.Api.SecureUsers.current,
      files: state => state.Api.SecureFiles.all
    }),
    previewFile() {
      return this.files.find(({fileid}) => fileid === this.previewFileId)
    },
    enabledProducts() {
      return Object
          .entries(this.products)
          // eslint-disable-next-line no-unused-vars
          .filter(([_k, product]) => product.enabled)
    },
  },
  created() {
    // initialize unobserved
    this.uploading = false;
    this.pollingFiles = {};

    // fetch init data
    this.fetchFileList();
  },
  methods: {
    ...mapActions({
      uploadFile: "Api/SecureFiles/upload",
      getFileList: "Api/SecureFiles/listMasschecks",
      getFileDetail: "Api/SecureFiles/masscheckDetail",
      startCheck: "Api/SecureFiles/startMasscheck",
      getProgress: "Api/SecureFiles/masscheckProgress",
      getCheckResult: "Api/SecureFiles/masscheckResult",
      getCheckReport: "Api/SecureFiles/masscheckReport",
      deleteFile: "Api/SecureFiles/deleteMasscheck",
    }),
    ...mapMutations({
      addFile: "Api/SecureFiles/addOrUpdate",
      removeFile: "Api/SecureFiles/remove",
      updateUser: "Api/SecureUsers/updateCurrent"
    }),
    getLocalizedFileListColumn(column) {
      const localizedColumns = {
        'status': this.$t('mass_check.file_list.columns.status'),
        'filename': this.$t('mass_check.file_list.columns.filename'),
        'progress': this.$t('mass_check.file_list.columns.progress'),
        'actions': ' ',
      }

      return localizedColumns?.[column] || column
    },
    async fetchFileList() {
      try {
        const fileList = await this.getFileList({})

        fileList.forEach(file => this.addFile({
          item: file,
          compareKey: 'fileid',
          merge: true
        }))

        this.handleProgressPolling(fileList)

      } catch (e) {
        this.$apiErrorHandler(e)
      }

    },
    resetUploadForm() {
      this.selectedFiles = [];
      this.$refs.upload_form.files = [];
      this.uploadProgress = null
    },
    closeUploadModal() {
      if (this.uploading) {
        return
      }

      this.resetUploadForm();
    },
    async startFileUpload() {
      try {
        const uploadedFile = await this.uploadFile({
          tx_adresslaborsitepackage_adresslaborsecureapi: {
            data: {
              file: this.selectedFiles?.[0].file,
              products: this.enabledProducts.map(([product]) => product).join(),
              charset: 'auto',
              delimiter: ';',
              quotechar: ''
            }
          },
          $uploadProgressCallback: progress => this.uploadProgress = progress
        })


        if (uploadedFile.error) {
          throw new ApiError(uploadedFile.error)
        }

        this.previewFileId = uploadedFile.fileid;
        this.addFile({
          item: uploadedFile,
          compareKey: "fileid",
          merge: true,
          addBefore: true
        })


        await this.fetchFileList()
      } catch (e) {
        this.$apiErrorHandler(e)
      }

      this.resetUploadForm();
    },
    async tryDeleteFile({fileid}) {
      try {
        await this.deleteFile({
          tx_adresslaborsitepackage_adresslaborsecureapi: {
            fileId: fileid
          }
        })

        this.removeFile({id: fileid, key: 'fileid'})

      } catch (e) {
        this.$apiErrorHandler(e)
      }

    },
    async showFilePreview({fileid, deleteTime}) {

      this.previewFileId = fileid

      try {
        if (!deleteTime) {
          const detail = await this.getFileDetail({
            tx_adresslaborsitepackage_adresslaborsecureapi: {
              fileId: fileid
            }
          })
          this.addFile({item: detail, compareKey: 'fileid', merge: true})
        }
      } catch (e) {
        this.$apiErrorHandler(e)
      }

    },
    async checkFile({fileid}) {
      try {
        const result = await this.startCheck({
          tx_adresslaborsitepackage_adresslaborsecureapi: {
            fileId: fileid
          }
        });

        this.addFile({
          item: result,
          compareKey: 'fileid',
          merge: true
        })

        this.updateUser({item: {credits: result.availablecredits}})

        this.handleProgressPolling([result])

      } catch (e) {
        this.$apiErrorHandler(e)
      }

    },
    downloadCheckResult({fileid, filename}) {
      this.getCheckResult({
        tx_adresslaborsitepackage_adresslaborsecureapi: {
          fileId: fileid
        }
      })
          .then(blob => this.downloadBlob(blob, this.$t('mass_check.result.filename', {filename})))
          .catch(this.$apiErrorHandler)
    },
    downloadCheckReport({fileid, filename}) {
      this.getCheckReport({
        tx_adresslaborsitepackage_adresslaborsecureapi: {
          fileId: fileid
        }
      })
          .then(blob => this.downloadBlob(blob, this.$t('mass_check.report.filename', {filename})))
          .catch(this.$apiErrorHandler)
    },
    handleProgressPolling(fileList) {
      const checkingFiles = fileList.filter(({status}) => status === "CHECKING")

      const isPollingFile = ({fileid}) => !!this.pollingFiles[fileid]
      const startPromisePolling = ({fileid}) => {
        return this.getProgress({
          tx_adresslaborsitepackage_adresslaborsecureapi: {
            fileId: fileid
          }
        })
            .then(file => {
              this.addFile({
                item: file,
                compareKey: 'fileid',
                merge: true
              })

              if (file.status === 'FINISHED') {
                throw "nothing to do"
              }

              return file
            })
            .then(file => new Promise(resolve => {
              window.setTimeout(() => resolve(file), PROGRESS_TIMEOUT)
            }))
            .then(file => startPromisePolling(file))
            .catch(console.debug)

      }

      checkingFiles.forEach(file => {
        if (!isPollingFile(file)) {
          this.pollingFiles[file.fileid] = startPromisePolling(file)
        }
      })
    },
    getEstimatedTime({eta}) {
      return eta
              ?.split('.')
              .shift()
              .split(':')
              .map(part => parseInt(part))
              .map((part, index) => {
                if (part === 0) {
                  return undefined
                }

                switch (index) {
                  case 0:
                    return this.$t('mass_check.progress.eta.hours', {n: part})
                  case 1:
                    return this.$t('mass_check.progress.eta.minutes', {n: part})
                  case 2:
                    return this.$t('mass_check.progress.eta.seconds', {n: part})
                }
              })
              .filter(part => !!part)
              .join(' ')
          || this.$t('mass_check.progress.eta.none');
    }
  }
}
</script>
