import ApiAppMixin from "pixelstein-vue-app-package/src/mixins/api-app-mixin";

export default {
	
	mixins: [ApiAppMixin],
	data() {
		return {
			preventAuthTokenCheck: true,
		}
	},
	computed: {
		genders() {
			return [
				{id: 'male', label: this.$t('salutation.m')},
				{id: 'female', label: this.$t('salutation.w')},
				// {id: 'Unbekannt', label: this.$t('salutation.unknown')}
			]
		},
		salutations() {
			return [
				{id: 'Herr', label: this.$t('salutation.m')},
				{id: 'Frau', label: this.$t('salutation.w')},
				{id: 'Unbekannt', label: this.$t('salutation.unknown')}
			]
		},
		countries() {
			return [
				{id: 'DE', label: this.$t('countries.de')},
				{id: 'AT', label: this.$t('countries.at')},
				{id: 'CH', label: this.$t('countries.ch')},
				{id: 'LI', label: this.$t('countries.li')}
			]
		}
	},
	methods: {
		getLocalizedGender(gender) {
			return this.genders.find(({id}) => id === gender)?.label || gender
		},
		getLocalizedProductName(check) {
			switch (check) {
				case "sc":
					return this.$t('product.sc')
				case "sc_int":
					return this.$t('product.sc_int')
				case "nc":
					return this.$t('product.nc')
				case "fk":
					return this.$t('product.fk')
				case "em":
					return this.$t('product.em')
				case "emx":
					return this.$t('product.em')
				case "pb":
					return this.$t('product.pb')
				case "vatid":
					return this.$t('product.vatid')
			}
		},
		downloadBlob(blob, filename) {
			return new Promise(((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => {
					
					// this.$nextTick().then(() => event.target.click())
					const a = document.createElement('a');
					a.href = reader.result;
					a.download = filename
					
					a.click()
					
					resolve(reader.result)
				}
				
				reader.onerror = () => {
					reject(reader.error)
				}
				
				reader.readAsDataURL(blob)
			}))
		}
	}
}
