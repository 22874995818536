<template>
  <div id="account_details" class="">
    <equal-height id="profil_1" item-id="account_details">

      <h3>{{ $t('account_details.header') }}</h3>
      <div class="table-responsive">
        <table>
          <tr v-for="key in ['credits', 'apikey', 'apicid']">
            <td class="key">{{ getLabelForRow(key) }}</td>
            <td class="separator">:</td>
            <td class="value">
              <pre>{{ user?.[key] || '-' }}</pre>
            </td>
          </tr>
        </table>
      </div>
    </equal-height>
  </div>
</template>

<script>
import {mapState} from "vuex";

import AppCore from "@/mixins/app-core";
import EqualHeight from "@/components/EqualHeight";

export default {
  name: 'AccountDetails',
  components: {EqualHeight},
  mixins: [AppCore],
  computed: {
    ...mapState({
      user: state => state.Api.SecureUsers.current
    })
  },
  methods: {
    getLabelForRow(key) {
      switch (key) {
        case 'credits':
          return this.$t('account_details.row.credits')
        case 'apikey':
          return this.$t('account_details.row.apikey')
        case 'apicid':
          return this.$t('account_details.row.apicid')
      }
    }
  }
}
</script>
