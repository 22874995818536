<template>
  <ps-modal-general-modal
      v-if="displayModal"
      dialog-classes="modal-lg"
      @close="hidden = true"
  >
    <template #header>
      <h1 class="mb-0" v-html="$t('privacy_view_modal.header')"/>
    </template>
    <accept-privacy/>
  </ps-modal-general-modal>
</template>

<script>

// API Errors will always respond with code 200 but the result will contain an "error" key a messages
const GDPR_ISSUE_MESSAGES = [
  "Datei enthält Daten, für die eine DSVGO-Vereinbarung notwendig ist. Bitte unterzeichnen Sie die Vereinbarung unter Mein Konto oder kontaktieren Sie uns.",
  "uebergebene Daten benoetigen eine DSGVO-Vereinbarung zur Auftragsverarbeitung. Bitte auf www.adresslabor.de anmelden."
]

import PsModalGeneralModal from "pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal";
import AcceptPrivacy from "@/views/AcceptPrivacy";

export default {
  name: "PrivacyViewModal",
  components: {PsModalGeneralModal, AcceptPrivacy},
  props: {
    accepted: {type: Boolean, default: false}
  },
  data() {
    return {
      isGdprError: false,
      hidden: true,
      apiError: null
    }
  },
  watch: {
    accepted(nv) {
      const storedHandler = this.apiError?.request?.handler
      if (typeof storedHandler === "function" && nv) {
        storedHandler()
        this.apiError = null;
        this.hidden = null
      }
    }
  },
  computed: {
    displayModal() {
      return this.isGdprError && !this.accepted && !this.hidden
    }
  },
  created() {
    this.$apiErrorHandlerPlugin.addMiddleware(this.gdprMiddleWare)
  },
  beforeDestroy() {
    this.$apiErrorHandlerPlugin.removeMiddleware(this.gdprMiddleWare)
  },
  methods: {
    gdprMiddleWare(apiError) {
      this.isGdprError = !!GDPR_ISSUE_MESSAGES.find(message => message === apiError.message)
      if (this.isGdprError) {
        this.apiError = apiError
        this.hidden = false
      }
    }
  }
}
</script>
