<template>
  <div id="orders">
    <div v-for="invoice in invoices">
      <h2>{{ $t('orders.header', {dateTime: toFormattedDateTime(invoice.invoicetimestamp)}) }}</h2>
      <div class="row mb-5">
        <div class="col-lg-4">
          <div class="bg-white"
               style="padding-left: var(--frame-spacing);padding-top: var(--frame-spacing);padding-bottom: var(--frame-spacing);padding-right: var(--frame-spacing);">
            <equal-height :id="'invoice_'+invoice.invoiceno" item-id="left">
              <h3>{{ $t('orders.order_detail.header') }}</h3>
              <p class="font-weight-bold h4">{{ $t('orders.order_detail.credits', invoice) }}</p>
              <p class="font-weight-bold h4">{{ $t('orders.order_detail.price', invoice) }}</p>
              <p>{{ $t('orders.order_detail.invoice_dl_before') }}
                <a :href="invoicePdfs[invoice.invoiceno]"
                   @click="fetchInvoice($event, invoice)"
                   :download="$t('orders.invoice_filename', invoice)"
                   :class="{loading: invoicePdfs[invoice.invoiceno] === false}"
                >{{ $t('orders.order_detail.invoice_dl_link', invoice) }}</a>
                {{ $t('orders.order_detail.invoice_dl_after') }}</p>
            </equal-height>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="bg-white"
               style="padding-left: var(--frame-spacing);padding-top: var(--frame-spacing);padding-bottom: var(--frame-spacing);padding-right: var(--frame-spacing);">
            <equal-height :id="'invoice_'+invoice.invoiceno" item-id="right">
              <h3>{{ $t('orders.invoice_detail.header') }}</h3>
              <div class="row">
                <div class="col-sm-6">
                  {{ invoice.company }}
                  <p>{{ $t('orders.invoice_detail.vatin', invoice) }}</p>
                </div>
                <div class="col-sm-6">
                  {{ invoice.firstname }} {{ invoice.lastname }}<br>
                  {{ invoice.zusatz }}<br>
                  {{ invoice.street }} {{ invoice.houseno }}<br>
                  {{ invoice.zip }} {{ invoice.city }}
                </div>
              </div>
            </equal-height>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

import AppCore from "@/mixins/app-core";
import DateFormats from "pixelstein-vue-app-package/src/mixins/date-formats";
import EqualHeight from "@/components/EqualHeight";

export default {
  name: 'Orders',
  components: {EqualHeight},
  mixins: [AppCore, DateFormats],
  data() {
    return {
      invoices: [],
      invoicePdfs: {}
    }
  },
  created() {
    this.fetchInvoices()
  },
  methods: {
    ...mapActions({
      getInvoices: "Api/SecureUsers/invoices",
      getInvoice: "Api/SecureUsers/invoice"
    }),
    async fetchInvoices() {
      this.invoices = await this.getInvoices({})

      console.log('invoices => ', this.invoices)

    },
    fetchInvoice(event, invoice) {
      if (!this.invoicePdfs[invoice.invoiceno]) {
        event.preventDefault()
      }

      if (this.invoicePdfs[invoice.invoiceno] === null) {
        return
      }

      this.invoicePdfs[invoice.invoiceno] = null;

      this.getInvoice({
        tx_adresslaborsitepackage_adresslaborsecureapi: {
          invoiceNo: invoice.invoiceno
        }
      })
          .then(blob => this.downloadBlob(blob, this.$t('orders.invoice_filename', invoice)))
          .catch(e => {
            delete this.invoicePdfs[invoice.invoiceno];
            this.$apiErrorHandler(e)
          })


    }
  },

}
</script>
