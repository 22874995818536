<template>
  <div id="user_details" class="user_details" v-if="user">
    <equal-height id="profil_1" item-id="user_details">
      <h3>{{ $t('user_details.header') }}</h3>
      <p>
        {{
          $t('user_details.full_username', {
            ...user,
            gender: getLocalizedGender(user.gender)
          })
        }}
      </p>
      <p>
        {{ $t('user_details.email', user) }}
      </p>
      <p v-if="user.aborg_active">
        <strong>{{ $t('user_details.invoice_abo.active') }}</strong><br>
        {{ $t('user_details.invoice_abo.value', {aborg_betrag: parseFloat(user.aborg_betrag).toLocaleString()}) }}<br>
        {{ $t('user_details.invoice_abo.min_credits', user) }}<br>
      </p>

      <button v-if="!user.dsgvo_contract"
              type="button"
              class="btn btn-outline-secondary btn-block"
              @click="showGdprModal = true"
      >
        {{ $t('user_details.gdpr') }}
      </button>

      <button
          form="logout"
          class="btn btn-dark btn-block mt-4"
      >
        {{ $t('user_details.logout') }}
      </button>
    </equal-height>
    <ps-modal-general-modal
        v-if="showGdprModal"
        dialog-classes="modal-lg"
        @close="showGdprModal = false"

    >
      <template #header>
        <h1 class="mb-0" v-html="$t('privacy_view_modal.header')"/>
      </template>
      <accept-privacy/>
    </ps-modal-general-modal>
  </div>
</template>

<script>
import {mapState} from "vuex";

import AppCore from "@/mixins/app-core";

import AcceptPrivacy from "@/views/AcceptPrivacy";

import EqualHeight from "@/components/EqualHeight";
import PsModalGeneralModal from "pixelstein-vue-app-package/src/vue2/PsModal/PsModalGeneralModal";

export default {
  name: 'UserDetails',
  components: {PsModalGeneralModal, AcceptPrivacy, EqualHeight},
  mixins: [AppCore],
  data() {
    return {
      showGdprModal: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.Api.SecureUsers.current
    })
  },
  methods: {
    getLabelForRow(key) {
      switch (key) {
        case 'credits':
          return this.$t('account_details.row.credits')
        case 'apikey':
          return this.$t('account_details.row.apikey')
        case 'apicid':
          return this.$t('account_details.row.apicid')
      }
    }
  }
}
</script>
