<template>
  <div class="accept-privacy">

    <form v-if="!preview"
          class="settings"
          @submit.prevent="preview = true"
    >
      <h3>{{ $t('accept_privacy.header') }}</h3>

      <div class="mb-4" v-html="$t('accept_privacy.into', $config).split('\n').join('<br>')"/>

      <strong class="d-inline-block mb-3">{{ $t('accept_privacy.category_header') }}</strong>

      <form-switch
          :label="$t('accept_privacy.category.customer')"
          :required="!someCategorySelected"
          v-model="categories.customer"
      />
      <form-switch
          :label="$t('accept_privacy.category.leads')"
          :required="!someCategorySelected"
          v-model="categories.leads"
      />
      <form-switch
          :label="$t('accept_privacy.category.suppliers')"
          :required="!someCategorySelected"
          v-model="categories.suppliers"
      />
      <form-switch
          :label="$t('accept_privacy.category.subscribers')"
          :required="!someCategorySelected"
          v-model="categories.subscribers"
      />
      <form-switch
          :label="$t('accept_privacy.category.donators')"
          :required="!someCategorySelected"
          v-model="categories.donators"
      />
      <form-input
          :label="$t('accept_privacy.category.others')"
          class="mt-3"
          :required="!someCategorySelected"
          v-model.trim="categories.others"
      />

      <h4 class="mt-4">{{ $t('accept_privacy.signatory') }}</h4>

      <form-input
          :label="null"
          required
          v-model.trim="signatory"
      />
      <button
          type="submit"
          class="btn btn-secondary btn-block"
          :disabled="!someCategorySelected"
      >
        {{ $t('accept_privacy.preview') }}
      </button>
    </form>
    <div v-else class="preview">

      <div style="max-height: 50vh; overflow: auto" v-html="contractHtml"/>

      <button
          type="button"
          class="btn btn-outline-secondary btn-block my-4"
          :disabled="!someCategorySelected"
          @click="fetchPdfPreview"
      >
        {{ $t('accept_privacy.pdf_preview') }}
      </button>

      <button
          type="button"
          class="btn btn-outline-dark btn-block my-4"
          :disabled="!someCategorySelected"
          @click="preview = false"
      >
        {{ $t('accept_privacy.hide_preview') }}
      </button>

      <button
          type="button"
          class="btn btn-primary btn-block my-4"
          :disabled="!someCategorySelected"
          @click="tryAcceptGdpr"
      >
        {{ $t('accept_privacy.submit') }}
      </button>

    </div>

    <a :href="$config.DSGVO_TOM_URL" class="my-4 d-inline-block" target="_blank">
      {{ $t('accept_privacy.TOM_link_text') }}
    </a>

  </div>
</template>

<script>
import FormSwitch from "@pixelstein/ps-form/components/PsFormSwitch";
import FormInput from "@pixelstein/ps-form/components/PsFormInput";
import {mapActions, mapMutations} from "vuex";
import AppCore from "@/mixins/app-core";
import DateFormats from "pixelstein-vue-app-package/src/mixins/date-formats";

export default {
  name: "AcceptPrivacy",
  mixins: [AppCore, DateFormats],
  components: {
    FormSwitch,
    FormInput
  },
  data() {
    return {
      categories: {
        customer: false,
        leads: false,
        suppliers: false,
        subscribers: false,
        donators: false,
        others: ""
      },
      signatory: '',
      preview: false,
      gdprPreviewResult: null
    }
  },
  watch: {
    categories: {
      deep: true,
      handler() {
        this.$nextTick()
            .then(this.fetchGdprPreview)
      }
    }
  },
  computed: {
    someCategorySelected() {
      return Object
          .values(this.categories)
          .some(category => !!category)
    },
    csvCategories() {
      return Object
          .entries(this.categories)
          .filter(([_, checked]) => !!checked)
          .map(([category]) => category)
          .join()
    },
    contractHtml() {
      return this.gdprPreviewResult
          ?.contract
          .split('\n')
          .join('<br>')
    }
  },
  methods: {
    ...mapActions({
      gdprPreview: "Api/SecureUsers/gdprPreview",
      gdprPdfPreview: "Api/SecureUsers/gdprPdfPreview",
      acceptGdpr: "Api/SecureUsers/acceptGdpr",
      gdpr: "Api/SecureUsers/gdpr"
    }),
    ...mapMutations({
      updateUser: "Api/SecureUsers/updateCurrent"
    }),
    fetchPdfPreview() {
      this.gdprPdfPreview({
        tx_adresslaborsitepackage_adresslaborsecureapi: {
          categories: this.csvCategories
        }
      })
          .then(blob => this.downloadBlob(blob, this.$t('accept_privacy.pdf_preview_filename')))
          .catch(this.$apiErrorHandler)
    },
    async fetchGdprPreview() {


      if (!this.someCategorySelected) {
        return
      }

      this.gdprPreviewResult = await this.gdprPreview({
        tx_adresslaborsitepackage_adresslaborsecureapi: {
          categories: this.csvCategories
        }
      })
    },
    tryAcceptGdpr() {
      this.acceptGdpr({
        tx_adresslaborsitepackage_adresslaborsecureapi: {
          categories: this.csvCategories,
          signatory: this.signatory,
          accepted: true // always true because the user accepts the policy by submitting the form
        }
      })
          .then(user => this.updateUser({item: user}))
          .catch(this.$apiErrorHandler)

    }
  }
}
</script>
