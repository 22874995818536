<template>
  <div class="check row bg-light py-2">
    <div class="col-md-5 mb-3 ">
      <div class="form-row bg-white h-100 px-3">
        <div class="col-12">
          <h2>{{ $t('check.choose_checks') }}</h2>
          <form-switch :label="$t('product.sc')" v-model="products.SC.enabled"/>
          <p class="small">{{ $t('product_description.sc') }}</p>
          <form-switch :label="$t('product.fk')" v-model="products.FK.enabled"/>
          <p class="small">{{ $t('product_description.fk') }}</p>
          <form-switch :label="$t('product.nc')" v-model="products.NC.enabled"/>
          <p class="small">{{ $t('product_description.nc') }}</p>
          <form-switch :label="$t('product.emx')" v-model="products.EMX.enabled"/>
          <p class="small">{{ $t('product_description.em') }}</p>
          <form-switch :label="$t('product.pbt')" v-model="products.PBT.enabled"/>
          <p class="small">{{ $t('product_description.pbt') }}</p>
          <form-switch :label="$t('product.vatid')" v-model="products.VATID.enabled"/>
          <p class="small">{{ $t('product_description.vatid') }}</p>
        </div>
      </div>
    </div>

    <form id="check_form"
          class="col-md-7 order-md-0 mb-3"
          @submit.prevent="onSubmit"
    >
      <button type="reset" class="btn btn-sm btn-dark position-absolute" style="top: 0; right: 15px; z-index: 99">
        {{ $t('field.reset') }}
      </button>
      <div class="form-row bg-white h-100 px-3">
        <div class="col-12">
          <h2 class="d-flex justify-content-between align-items-start mr-n1">
            {{ $t('check.enter_data') }}
          </h2>
        </div>
        <div class="col-sm-6">
          <form-select :label="$t('field.salutation')"
                       :disabled="!isFieldEnabled('salutation')"
                       :required="isFieldRequired('salutation')"
                       name="salutation"
                       :options="salutations"
                       v-model="fields.salutation"/>
        </div>
        <div class="col-sm-6">
          <form-input :label="$t('field.title')"
                      :disabled="!isFieldEnabled('title')"
                      :required="isFieldRequired('title')"
                      name="title"
                      v-model="fields.title"
          />
        </div>
        <div class="col-sm-6">
          <form-input :label="$t('field.firstname')"
                      :disabled="!isFieldEnabled('firstname')"
                      :required="isFieldRequired('firstname')"
                      name="firstname"
                      v-model="fields.firstname"
          />
        </div>
        <div class="col-sm-6">
          <form-input :label="$t('field.lastname')"
                      :disabled="!isFieldEnabled('lastname')"
                      :required="isFieldRequired('lastname')"
                      name="lastname"
                      v-model="fields.lastname"
          />
        </div>
        <div class="col-sm-12">
          <form-select :label="$t('field.country')"
                       :disabled="!isFieldEnabled('country')"
                       :required="isFieldRequired('country')"
                       :options="countries"
                       name="country"
                       v-model="fields.country"
          />
        </div>
        <div class="col-sm-3">
          <form-input :label="$t('field.zip')"
                      :disabled="!isFieldEnabled('zip')"
                      :required="isFieldRequired('zip')"
                      name="zip"
                      v-model="fields.zip"
          />
        </div>
        <div class="col-sm-9">
          <form-input :label="$t('field.city')"
                      :disabled="!isFieldEnabled('city')"
                      :required="isFieldRequired('city')"
                      name="city"
                      v-model="fields.city"
          />
        </div>
        <div class="col-sm-10">
          <form-input :label="$t('field.street')"
                      :disabled="!isFieldEnabled('street')"
                      :required="isFieldRequired('street')"
                      name="street"
                      v-model="fields.street"
          />
        </div>
        <div class="col-sm-2">
          <form-input :label="$t('field.hno')"
                      :disabled="!isFieldEnabled('hno')"
                      :required="isFieldRequired('hno')"
                      name="hno"
                      v-model="fields.hno"
          />
        </div>
        <div class="col-sm-6">
          <form-input :label="$t('field.phone')"
                      :disabled="!isFieldEnabled('phone')"
                      :required="isFieldRequired('phone')"
                      name="phone"
                      v-model="fields.phone"
          />
        </div>
        <div class="col-sm-6">
          <form-input :label="$t('field.email')"
                      :disabled="!isFieldEnabled('email')"
                      :required="isFieldRequired('email')"
                      name="email"
                      v-model="fields.email"
          />
        </div>
        <div class="col-sm-6">
          <form-input :label="$t('field.vatin')"
                      :disabled="!isFieldEnabled('vatin')"
                      :required="isFieldRequired('vatin')"
                      name="vatin"
                      v-model="fields.vatin"
          />
        </div>
        <div class="col-12 small mb-2">
          {{ $t('fields.mandatory') }}
        </div>
      </div>
    </form>

    <div class="col-12 order-2 text-center">
      <button form="check_form"
              class="btn btn-secondary text-white btn-lg my-4 position-relative"
              :disabled="loading || enabledProducts.length === 0"
      >
        <div v-if="loading" class="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
             style="top: 0; left: 0; background: rgba(0,160, 227,.4)">
          <loading-icon class="fa-2x text-dark" style="margin: auto; "/>
        </div>

        <template>
          {{ $t('check.validate') }}<br>
          <span class="small">{{
              $t('check.validate_price', {
                credits: estimatedPriceInCredits,
              })
            }}</span>
        </template>
      </button>
    </div>

    <div class="col-12 order-3 text-center">

      <img v-if="loading || checkResult" ref="stripe" src="@/assets/spritemap.png" class="img-fluid mb-4" alt="—">

      <div ref="result" class="row mb-5">
        <div :class="sortedResult.every(([c]) => isLargeResult(c))?'col-12':'col-lg-8'"
             class=" d-flex flex-column">
          <div v-for="([check,result]) in sortedResult.filter(([c]) => isLargeResult(c))"
               :key="check"
               class="mb-4 h-100"
          >
            <div class="result p-2 h-100"
                 :class="[check, [result].flat()[0].trafficlight]"
            >
              <h2>{{ getLocalizedProductName(check) }}</h2>
              <div class="result-text">
                {{ [result].flat()[0].resulttext }}
              </div>
              <div class="table-responsive mt-4 table-striped">
                <table class="table w-100">
                  <thead>
                  <tr>
                    <template v-for="(_value,key) in getResultDetails([result].flat()[0])">
                      <th class="p-1" :class="key">{{ key }}</th>
                    </template>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="res in [result].flat()">
                    <template v-for="(value,key) in getResultDetails(res)">
                      <td class="p-1" :class="key">{{ value }}</td>
                    </template>

                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div :class="sortedResult.some(([c]) => isLargeResult(c))?'col-lg-4 flex-column':'col-12 row'"
             class="d-flex">
          <div v-for="([check,result]) in sortedResult.filter(([c]) => !isLargeResult(c))"
               :key="check"
               class="mb-4 h-100"
               :class="sortedResult.some(([c]) => isLargeResult(c))||' col'"
          >
            <div v-for="res in [result].flat()"
                 class="result p-2 h-100"
                 :class="[check, res.trafficlight]"
            >
              <h2 class="mr-4">{{ getLocalizedProductName(check) }}</h2>
              <div class="result-text">
                {{ $t('check.result_text', res) }}
              </div>
              <div class="table-responsive mt-4 table-striped">
                <table v-if="isLargeResult" class="table w-100">
                  <tr v-for="(value,key) in getResultDetails(res)">
                    <template>
                      <th class="p-1">{{ key }}</th>
                      <td>{{ value }}</td>
                    </template>
                  </tr>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <debug-only>
        <h4>DEBUG</h4>
        RESULT
        <pre class="text-left">{{ checkResult }}</pre>

        filteredRates:
        <pre class="text-left">{{ filteredRates }}</pre>
      </debug-only>

    </div>

    <privacy-view-modal
        :accepted="!!user?.dsgvo_contract"
    />
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';

import _cloneDeep from 'lodash/cloneDeep';

import AppCore from '@/mixins/app-core';

import FormInput    from '@pixelstein/ps-form/components/PsFormInput';
import FormSelect   from '@pixelstein/ps-form/components/PsFormSelect';
import FormSwitch   from '@pixelstein/ps-form/components/PsFormSwitch';
import FormCheckbox from '@pixelstein/ps-form/components/PsFormCheckbox';

import DebugOnly        from '@/components/DebugOnly';
import LoadingIcon      from '@/components/LoadingIcon';
import ApiError         from '@pixelstein/api-store-utils/dist/errors/ApiError';
import PrivacyViewModal from '@/components/PrivacyViewModal';

export default {
  name: 'Check',
  mixins: [AppCore],
  components: {PrivacyViewModal, LoadingIcon, DebugOnly, FormInput, FormSelect, FormSwitch, FormCheckbox},
  data() {
    return {
      products: {
        SC: {
          enabled: false,
          enabledFields: ['country', 'zip', 'city', 'street', 'hno'],
          requiredFields: ['country', 'zip', 'city', 'street', 'hno'],
        },
        SCX: {
          enabled: false,
          enabledFields: [],
          requiredFields: [],
        },
        SC_INT: {
          enabled: false,
          enabledFields: [],
          requiredFields: [],
        },
        FK: {
          enabled: false,
          enabledFields: ['firstname', 'lastname', 'city', 'street', 'phone'],
          requiredFields: ['firstname', 'lastname'],
        },
        NC: {
          enabled: false,
          enabledFields: ['firstname', 'lastname', 'salutation', 'title'],
          requiredFields: ['lastname'],
        },
        EM: {
          enabled: false,
          enabledFields: ['email'],
          requiredFields: ['email'],
        },
        EMX: {
          enabled: false,
          enabledFields: ['email'],
          requiredFields: ['email'],
        },
        PB: {
          enabled: false,
          enabledFields: [],
          requiredFields: [],
        },
        PBT: {
          enabled: false,
          enabledFields: ['firstname', 'lastname', 'country', 'zip', 'city', 'street', 'hno', 'phone'],
          requiredFields: ['lastname', 'country'],
        },
        VATID: {
          enabled: false,
          enabledFields: ['vatin'],
          requiredFields: ['vatin'],
        },
        VATIDX: {
          enabled: false,
          enabledFields: ['vatin'],
          requiredFields: ['vatin'],
        },
      },
      fields: {
        salutation: '',
        title: '',
        firstname: '',
        lastname: '',
        street: '',
        hno: '',
        zip: '',
        city: '',
        country: '',
        phone: '',
        email: '',
        vatin: '',
        birthday: '',
        state: '',
        zip_add_on: '',
        special_zip: '',
        po_box_no: '',
        house_estate: '',
        location: '',
        sub_building_name: '',
        organisation: '',
      },
      checkResult: null,
      saveData: false,
      loading: false,
    };
  },
  watch: {
    fields: {
      deep: true,
      handler(nv) {
        sessionStorage.setItem('check.fields', JSON.stringify(nv));
      },
    },
  },
  computed: {
    ...mapState({
      rates: state => state.Api.SecureRates.all,
      user: state => state.Api.SecureUsers.current,
    }),
    filteredRates() {
      return this.rates
          .filter(rate => !rate.country || rate.country === this.fields.country);
    },
    estimatedPriceInCredits() {
      return this.enabledProducts
          .map(([key]) => this.filteredRates.find(({product}) => product.toLowerCase() === key.toLowerCase()))
          .map(rate => rate?.credits)
          .filter(el => !!el)
          .reduce((previous, current) => current + previous, 0);
    },
    sortedResult() {
      return ['sc', 'fk', 'pb', 'nc', 'em', 'vatid']
          .map(check => [check, this.checkResult?.[check]])
          .filter(result => !!result[1]);
    },
    enabledProducts() {
      return Object
          .entries(this.products)
          // eslint-disable-next-line no-unused-vars
          .filter(([_k, product]) => product.enabled);
    },
    enabledFields() {
      return [
        ...new Set(
            this.enabledProducts
                // eslint-disable-next-line no-unused-vars
                .flatMap(([_k, product]) => product.enabledFields),
        )];
    },
    requiredFields() {
      return [
        ...new Set(
            this.enabledProducts
                // eslint-disable-next-line no-unused-vars
                .flatMap(([_k, product]) => product.requiredFields),
        )];
    },
  },
  created() {
    const cachedFields = JSON.parse(sessionStorage.getItem('check.fields') || 'null') || {};
    this.fields = Object.assign(this.fields, cachedFields);

    this.fetchUserRates();
  },
  methods: {
    ...mapActions({
      check: 'Api/SecureChecks/check',
      userRates: 'Api/SecureRates/userRates',
    }),
    ...mapMutations({
      addUserRate: 'Api/SecureRates/add',
      updateUser: 'Api/SecureUsers/updateCurrent',
    }),
    async fetchUserRates() {
      try {
        const rates = await this.userRates({});
        rates.forEach(rate => this.addUserRate({item: rate, compareKey: 'product'}));

      } catch (e) {
        this.$apiErrorHandler(e);
      }

    },
    isFieldEnabled(field) {
      return !!this.enabledFields.find(f => f === field);
    },
    isFieldRequired(field) {
      return !!this.requiredFields.find(f => f === field);
    },
    isLargeResult(check) {
      return ['pbt', 'pb', 'sc', 'scx', 'sc_int', 'nc'].find(e => check === e);
    },
    getResultDetails(res) {
      const result = _cloneDeep(res);

      delete result.resulttext;
      delete result.trafficlight;

      return result;
    },
    async onSubmit() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$nextTick()
          .then(() => this.$refs.stripe.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          }));

      let fields = {};
      this.requiredFields.forEach(f => fields[f] = this.fields[f]);
      console.log({
        ...fields,
        product: this.enabledProducts.map(([k]) => k).join(','),
      });
      try {
        this.checkResult = await this.check({
          tx_adresslaborsitepackage_adresslaborsecureapi: {
            type: '16542',
            data: {
              ...fields,
              product: this.enabledProducts.map(([k]) => k).join(','),
            },
          },
        });

        if (this.checkResult.error) {
          throw new ApiError(this.checkResult.error, 'GDPR', '', {handler: this.onSubmit});
        }

        this.updateUser({item: {credits: this.checkResult.credits}});

        this.$nextTick()
            .then(() => this.$refs.result.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            }));
      } catch (e) {
        this.$apiErrorHandler(e);
      }

      this.loading = false;
    },
  },
};
</script>
