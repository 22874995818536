<template>
  <div id="app">
  </div>
</template>

<script>
import AppCore from "@/mixins/app-core";
import {mapActions, mapMutations} from "vuex";

import dayjs from 'dayjs'
import locale_de from 'dayjs/locale/de'
import locale_en from 'dayjs/locale/en'


export default {
  name: "App",
  mixins: [AppCore],
  created() {
    this.fetchUser()

    const lang = document.querySelector('html')?.lang?.split('-').shift()

    this.$i18n.locale = lang

    switch (lang) {
      case 'de':
        dayjs.locale(locale_de);
        break;
      case 'en':
        dayjs.locale(locale_en);
        break;
    }
  },
  methods: {
    ...mapActions({
      getUser: "Api/SecureUsers/user"
    }),
    ...mapMutations({
      updateUser: "Api/SecureUsers/updateCurrent"
    }),
    async fetchUser() {
      try {
        const user = await this.getUser({})
        this.updateUser({item: user});

        console.log(user)

      } catch (e) {
        this.$apiErrorHandler(e)
      }
    },
  }
}
</script>

<style lang="scss">
//@import "src/styles/main";
</style>
