<template>
  <div v-if="$config?.DEBUG" class="debug">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "DebugOnly"
}
</script>

<style scoped>

</style>
