<template>
  <div id="credits"
       v-if="user"
       class="credits"
       v-html="$t('credits.available', user)"
  ></div>
</template>

<script>
import {mapState} from "vuex";

import AppCore from "@/mixins/app-core";

export default {
  name: 'Credits',
  mixins: [AppCore],
  computed: {
    ...mapState({
      user: state => state.Api.SecureUsers.current
    })
  }
}
</script>
