<template>
  <div class="equal-height" :style="itemStyle">
    <div ref="inner" style="padding-top: .1px; padding-bottom: .1px">
      <slot/>
    </div>
  </div>
</template>

<script>
import {v1} from 'uuid'
import {mapMutations, mapState} from "vuex";

export default {
  name: "EqualHeight",
  props: {
    id: {type: String, default: 'default'},
    itemId: {type: String, default: () => v1()}
  },
  computed: {
    ...mapState({
      equalHeights: state => state.equalHeights
    }),
    itemStyle() {
      const heights = Object.values(this.equalHeights?.[this.id] || {})
      console.log("heights => ", heights, " MAX => ", Math.max.apply(Math, heights))
      return {
        "min-height": Math.max.apply(Math, heights) + 'px'
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.onResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapMutations({
      setEqualHeightItem: "setEqualHeightItem"
    }),
    onResize() {
      const height = this.$refs.inner.clientHeight
      this.setEqualHeightItem({
        id: this.id,
        itemId: this.itemId,
        height
      })
    }
  }
}
</script>

